import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';

const NotFoundPage: React.FC = () => (
  <Layout>
    The page you are looking for no longer exists.
    <Link to="/">Home</Link>
  </Layout>
);

export default NotFoundPage;
